<template>
  <div>
    <b-tabs
      pills
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="FileIcon" />
          <span>Sözleşme Oluştur</span>
        </template>
        <contract-form />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="UserIcon" />
          <span>Müşteri Kartı</span>
        </template>
        <customer />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BTabs, BTab,
} from 'bootstrap-vue'
import Customer from '@/views/Crm/View/Customer.vue'
import ContractForm from '@/views/Rental/Contracts/ContractForm.vue'

export default {
  name: 'Add',
  components: {
    BTabs,
    BTab,
    Customer,
    ContractForm,
  },
  created() {
    this.getCustomer()
    this.$store.commit('rentalContracts/RESET_DATA_ITEM')
  },
  methods: {
    getCustomer() {
      this.$store.dispatch('customers/customerView', this.$route.params.id)
    },
  },
}
</script>
